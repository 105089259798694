import SocketConnector from "./socket";
export default class RoomService {
    constructor(scene, connection, roomId, userId, isHost) {
        this.socketConnection = new SocketConnector(connection, scene);
        this.roomId = roomId;
        this.userId = userId;
        this.isHost = isHost;
    }
    getUserId() {
        return this.userId;
    }
    getRoomId() {
        return this.roomId;
    }
    IsHost() {
        return this.isHost;
    }
    sendMovePosition(x, y) {
        const position = { x: x, y: y };
        this.socketConnection.sendMovePosition(this.roomId, position);
    }
    sendShootPosition(position) {
        this.socketConnection.sendShootPosition(this.roomId, position);
    }
    startGame() {
        this.socketConnection.startGame(this.roomId);
    }
}
