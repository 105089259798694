export default class UIText {
    constructor(scene, x, y, text, fontSize) {
        this.text = document.createElement("h3");
        this.text.innerHTML = text;
        this.x = x;
        this.y = y;
        const gameCanvas = scene.game.canvas;
        const gameContainer = gameCanvas.parentElement;
        gameContainer === null || gameContainer === void 0 ? void 0 : gameContainer.appendChild(this.text);
        const textStyles = this.text.style;
        textStyles.position = "absolute";
        textStyles.fontSize = fontSize ? `${fontSize}px` : "100px";
        textStyles.left = `${x - this.text.offsetWidth / 2}px`;
        textStyles.top = `${y - this.text.offsetHeight}px`;
        textStyles.pointerEvents = "none";
    }
    update(text, fontSize) {
        if (fontSize !== undefined) {
            this.text.style.fontSize = `${fontSize}px`;
        }
        this.text.innerHTML = text;
        this.text.style.left = `${this.x - this.text.offsetWidth / 2}px`;
    }
    destroy() {
        this.text.remove();
    }
}
