export default class UIButton {
    constructor(scene, x, y, text, cb) {
        this.btn = document.createElement("button");
        this.btn.innerHTML = text;
        const gameCanvas = scene.game.canvas;
        const gameContainer = gameCanvas.parentElement;
        gameContainer.appendChild(this.btn);
        const btnStyle = this.btn.style;
        btnStyle.position = "absolute";
        btnStyle.backgroundColor = "#ffffff";
        btnStyle.color = "#000000";
        btnStyle.padding = "10px 20px";
        btnStyle.border = "none";
        btnStyle.cursor = "pointer";
        btnStyle.fontSize = "16px";
        btnStyle.fontWeight = "bold";
        btnStyle.width = `${scene.cameras.default.width / 4}px`;
        btnStyle.borderRadius = "10px";
        btnStyle.left = `${x - this.btn.offsetWidth / 2}px`;
        btnStyle.top = `${y - this.btn.offsetHeight / 2}px`;
        this.btn.onmouseover = () => {
            btnStyle.backgroundColor = "#fffada";
        };
        this.btn.onmouseout = () => {
            btnStyle.backgroundColor = "#ffffff";
        };
        this.btn.addEventListener("click", () => {
            cb && cb();
        });
    }
    destroy() {
        this.btn.remove();
    }
}
