import * as Phaser from "phaser";
import Cannonball from "./cannonball";
import { PLATFORM_HEIGHT, PLATFORM_WIDTH, WOOD_SPRITE_SIZE, } from "./platform";
var Vector2 = Phaser.Math.Vector2;
export const LEFT_PIRATE_POS = {
    x: WOOD_SPRITE_SIZE,
    y: WOOD_SPRITE_SIZE * 3.5,
};
export const RIGHT_PIRATE_POS = {
    x: WOOD_SPRITE_SIZE * 16,
    y: WOOD_SPRITE_SIZE * 3.5,
};
const MOVE_SPEED = 200;
export default class Pirate {
    constructor(scene, x, y, texture, isEnemy) {
        this.sceneRef = scene;
        this.movePosition = new Phaser.Math.Vector2(x, y);
        this.sprite = scene.physics.add.sprite(x, y, texture);
        this.sprite.setCollideWorldBounds(true);
        this.cannonball = new Cannonball(this.sceneRef);
        this.canMove = false;
        this.isEnemy = isEnemy || false;
        this.path = [];
    }
    moveNow(position) {
        this.isFindingPath = true;
        this.setCanMove(true);
        this.setMovePosition(position.x, position.y);
        this.findPath();
        this.update();
        this.isFindingPath = false;
    }
    shootNow(position) {
        const target = new Vector2(position.x, position.y + WOOD_SPRITE_SIZE / 2);
        const piratePosition = new Phaser.Math.Vector2(this.sprite.x, this.sprite.y);
        this.cannonball.shootTo(target, piratePosition);
    }
    setMovePosition(x, y) {
        this.movePosition = new Phaser.Math.Vector2(x, y);
    }
    findPath() {
        console.log("finding path");
        const frontiers = [];
        const visited = [];
        const start = {
            position: new Phaser.Math.Vector2(this.sprite.x, this.sprite.y + WOOD_SPRITE_SIZE / 2),
            gScore: 0,
            hScore: 0,
            fScore: 0,
            parent: null,
        };
        frontiers.push(start);
        const maxTries = PLATFORM_WIDTH *
            PLATFORM_HEIGHT *
            (PLATFORM_WIDTH * PLATFORM_HEIGHT);
        let tries = 0;
        while (frontiers.length > 0) {
            tries++;
            const current = this.findLowestFScoreNode(frontiers);
            const index = frontiers.indexOf(current);
            index > -1 && frontiers.splice(index, 1);
            visited.push(current);
            if (this.isDestination(current.position)) {
                console.log("is destination");
                this.path = this.reconstructPath(current);
                frontiers.splice(0, frontiers.length);
                return;
            }
            const neighbors = this.getNeighbors(current);
            for (const neighbor of neighbors) {
                if (visited.includes(neighbor)) {
                    continue;
                }
                const tentativeGScore = current.gScore + this.calculateDistance(current, neighbor);
                if (!frontiers.includes(neighbor)) {
                    frontiers.push(neighbor);
                }
                neighbor.parent = current;
                neighbor.gScore = tentativeGScore;
                neighbor.hScore = this.calculateHScore(neighbor);
                neighbor.fScore = neighbor.gScore + neighbor.hScore;
            }
            if (tries === maxTries)
                break;
        }
        console.log("no paths found");
    }
    calculateHScore(node) {
        return (Math.abs(node.position.x - this.movePosition.x) +
            Math.abs(node.position.y - this.movePosition.y));
    }
    calculateDistance(a, b) {
        return Phaser.Math.Distance.Between(a.position.x, a.position.y, b.position.x, b.position.y);
    }
    getNeighbors(node) {
        const neighbors = [];
        const rightNeighborPos = new Phaser.Math.Vector2(node.position.x + WOOD_SPRITE_SIZE, node.position.y);
        if (this.isValidNeighbor(rightNeighborPos)) {
            const rightNeigh = {
                position: rightNeighborPos,
                gScore: 0,
                hScore: 0,
                fScore: 0,
                parent: null,
            };
            neighbors.push(rightNeigh);
        }
        const leftNeighborPos = new Phaser.Math.Vector2(node.position.x - WOOD_SPRITE_SIZE, node.position.y);
        if (this.isValidNeighbor(leftNeighborPos)) {
            const leftNeigh = {
                position: leftNeighborPos,
                gScore: 0,
                hScore: 0,
                fScore: 0,
                parent: null,
            };
            neighbors.push(leftNeigh);
        }
        const topNeighborPos = new Phaser.Math.Vector2(node.position.x, node.position.y - WOOD_SPRITE_SIZE);
        if (this.isValidNeighbor(topNeighborPos)) {
            const topNeigh = {
                position: topNeighborPos,
                gScore: 0,
                hScore: 0,
                fScore: 0,
                parent: null,
            };
            neighbors.push(topNeigh);
        }
        const bottomNeighborPos = new Phaser.Math.Vector2(node.position.x, node.position.y + WOOD_SPRITE_SIZE);
        if (this.isValidNeighbor(bottomNeighborPos)) {
            const bottomNeigh = {
                position: bottomNeighborPos,
                gScore: 0,
                hScore: 0,
                fScore: 0,
                parent: null,
            };
            neighbors.push(bottomNeigh);
        }
        return neighbors;
    }
    isValidNeighbor(pos) {
        const blocks = this.isEnemy
            ? this.sceneRef.platformB.getBlocks().getChildren()
            : this.sceneRef.platformA.getBlocks().getChildren();
        const block = blocks.find((b) => {
            return b.x === pos.x && b.y === pos.y;
        });
        return block !== undefined;
    }
    isDestination(position) {
        return (position.x === this.movePosition.x &&
            position.y - WOOD_SPRITE_SIZE / 2 === this.movePosition.y);
    }
    reconstructPath(node) {
        const path = [];
        while (node !== null) {
            path.unshift(node.position);
            node = node.parent;
        }
        return path;
    }
    findLowestFScoreNode(nodes) {
        let lowestFScore = Infinity;
        let lowestFScoreNode = null;
        for (const node of nodes) {
            if (node.fScore < lowestFScore) {
                lowestFScore = node.fScore;
                lowestFScoreNode = node;
            }
        }
        return lowestFScoreNode;
    }
    setCanMove(move) {
        this.canMove = move;
    }
    destroy() {
        this.sprite.destroy();
    }
    update() {
        var _a;
        if (this.path.length > 0) {
            const { x: targetX, y: targetY } = this.path[0];
            const distance = Phaser.Math.Distance.Between(this.sprite.x, this.sprite.y, targetX, targetY - WOOD_SPRITE_SIZE / 2);
            if (distance < 5) {
                this.path.splice(0, 1);
                if (this.path.length === 0) {
                    this.sprite.setVelocity(0);
                    this.setCanMove(false);
                    (_a = this.sceneRef.children.getByName("selected")) === null || _a === void 0 ? void 0 : _a.destroy();
                    if (!this.isEnemy) {
                        //this.sceneRef.roomService.readyToShoot();
                    }
                }
                this.sprite.body.reset(targetX, targetY - WOOD_SPRITE_SIZE / 2);
            }
            else {
                this.canMove &&
                    this.sceneRef.physics.moveTo(this.sprite, targetX, targetY - WOOD_SPRITE_SIZE / 2, MOVE_SPEED);
            }
        }
    }
}
