var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import io from "socket.io-client";
const URL = process.env.BACKEND_FQDN;
const FALLBACK_TIMEOUT = 5000;
const COMMANDS = {
    createParty: "createParty",
    partyCreated: "partyCreated",
    joinParty: "joinParty",
    joinedParty: "joinedParty",
};
export default class StartPartyService {
    constructor() {
        this.socket = io(URL);
    }
    createParty() {
        return __awaiter(this, void 0, void 0, function* () {
            this.socket.emit(COMMANDS.createParty);
            return new Promise((resolve) => this.socket.on(COMMANDS.partyCreated, (roomId, userId) => {
                resolve({ roomId, userId });
            }));
        });
    }
    joinParty(name) {
        return __awaiter(this, void 0, void 0, function* () {
            this.socket.emit(COMMANDS.joinParty, name);
            return new Promise((resolve) => {
                const fallBack = setTimeout(() => resolve({ roomId: "", userId: "" }), FALLBACK_TIMEOUT);
                this.socket.on(COMMANDS.joinedParty, (roomId, userId) => {
                    clearTimeout(fallBack);
                    resolve({ roomId, userId });
                });
            });
        });
    }
    getSocket() {
        return this.socket;
    }
}
