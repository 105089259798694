var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _GamePlayUI_instances, _GamePlayUI_getRoomNameText, _GamePlayUI_fallbackCopyTextToClipboard, _GamePlayUI_copyTextToClipboard, _GamePlayUI_showCopied;
import UIText from "./uiText";
import UIButton from "./uiButton";
class GamePlayUI {
    constructor(scene, isHost, roomId) {
        _GamePlayUI_instances.add(this);
        const initalText = isHost ? "Waiting for players" : "Ready to start";
        const roomNameText = __classPrivateFieldGet(this, _GamePlayUI_instances, "m", _GamePlayUI_getRoomNameText).call(this, roomId);
        const moveText = "Left click to move your character";
        const shootText = "Right click to attack the enemy";
        this.sceneRef = scene;
        this.text = new UIText(scene, scene.cameras.main.width / 2, 18, initalText);
        this.finalText = scene.add.text(scene.cameras.main.width / 2 - 100, 128, roomNameText, { fontSize: 40, color: "black" });
        this.finalText.visible = false;
        this.roomNameText = scene.add
            .text(scene.cameras.main.width / 2, 128, roomNameText, {
            fontSize: 48,
            color: "black",
        })
            .setOrigin(0.5)
            .setInteractive()
            .on("pointerdown", () => __classPrivateFieldGet(this, _GamePlayUI_instances, "m", _GamePlayUI_copyTextToClipboard).call(this, roomId));
        this.copiedText = scene.add.text(scene.cameras.main.width / 2 + 80, 150, "Room name copied", { fontSize: 20, color: "gray" });
        this.copiedText.visible = false;
        const leftInitialTxt = isHost ? moveText : shootText;
        this.left_bottom_txt = new UIText(scene, 160, scene.cameras.main.height - 80, leftInitialTxt, 20);
        const rightInitialTxt = isHost ? shootText : moveText;
        this.right_bottom_txt = new UIText(scene, scene.cameras.main.width - 160, scene.cameras.main.height - 80, rightInitialTxt, 20);
    }
    updateText(text, fontSize) {
        this.text.update(text, fontSize);
    }
    updateFinalText(text) {
        this.finalText.text = text;
        this.finalText.visible = true;
    }
    updateCount(count) {
        if (!this.count) {
            this.count = new UIText(this.sceneRef, this.sceneRef.cameras.main.width / 2, 125, "10");
        }
        this.count.update(count);
    }
    destroyCount() {
        if (this.count) {
            this.count.destroy();
        }
    }
    showStartButton(show) {
        if (show) {
            this.starButton = new UIButton(this.sceneRef, this.sceneRef.cameras.main.width / 2, 225, "Start", () => this.sceneRef.roomService.startGame());
        }
        else {
            this.starButton && this.starButton.destroy();
        }
    }
    destroyRoomNameText() {
        this.roomNameText.destroy();
    }
}
_GamePlayUI_instances = new WeakSet(), _GamePlayUI_getRoomNameText = function _GamePlayUI_getRoomNameText(roomName) {
    return `Room name: ${roomName}`;
}, _GamePlayUI_fallbackCopyTextToClipboard = function _GamePlayUI_fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;
    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    try {
        var successful = document.execCommand("copy");
        var msg = successful ? "successful" : "unsuccessful";
        console.log("Fallback: Copying text command was " + msg);
    }
    catch (err) {
        console.error("Fallback: Oops, unable to copy", err);
    }
    document.body.removeChild(textArea);
}, _GamePlayUI_copyTextToClipboard = function _GamePlayUI_copyTextToClipboard(text) {
    __classPrivateFieldGet(this, _GamePlayUI_instances, "m", _GamePlayUI_showCopied).call(this);
    if (!navigator.clipboard) {
        __classPrivateFieldGet(this, _GamePlayUI_instances, "m", _GamePlayUI_fallbackCopyTextToClipboard).call(this, text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log("Async: Copying to clipboard was successful!");
    }, function (err) {
        console.error("Async: Could not copy text: ", err);
    });
}, _GamePlayUI_showCopied = function _GamePlayUI_showCopied() {
    clearTimeout(this.copiedTimeoutId);
    this.copiedText.visible = true;
    this.copiedTimeoutId = setTimeout(() => {
        this.copiedText.visible = false;
    }, 2000);
};
export default GamePlayUI;
