var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as Phaser from "phaser";
import UIButton from "../components/uiButton";
import UIInputContainer from "../components/uiInputContainer";
import StartPartyService from "../network/partyCreation";
export default class MainMenuScene extends Phaser.Scene {
    constructor() {
        super({ key: "MainMenuScene" });
        this.partyService = new StartPartyService();
    }
    create() {
        this.createAiButton = new UIButton(this, this.cameras.main.width / 2, 200, "Play vs Ai", () => this.createAiGame());
        this.createButton = new UIButton(this, this.cameras.main.width / 2, 250, "Create Party", () => this.createPartyClick());
        this.joinButton = new UIButton(this, this.cameras.main.width / 2, 300, "Join Party", () => this.joinPartyClick());
        this.creditsButton = new UIButton(this, this.cameras.main.width / 2, 350, "Credits", () => alert("Author: William Pederzoli"));
    }
    createAiGame() {
        return __awaiter(this, void 0, void 0, function* () {
            alert("Coming Soon");
        });
    }
    createPartyClick() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.startParty();
        });
    }
    joinPartyClick() {
        this.inputContainer = new UIInputContainer(this, "Join Party", "Join", (roomName) => this.joinParty(roomName));
    }
    startParty() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.partyService.createParty();
            if (res.roomId !== "") {
                console.log(res);
                this.clearUI();
                this.game.scene.start("GamePlayScene", {
                    connection: this.partyService.getSocket(),
                    roomId: res.roomId,
                    userId: res.userId,
                    isHost: true,
                });
            }
        });
    }
    joinParty(roomName) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield this.partyService.joinParty(roomName);
            if (res.roomId !== "") {
                this.clearUI();
                this.game.scene.start("GamePlayScene", {
                    connection: this.partyService.getSocket(),
                    roomId: res.roomId,
                    userId: res.userId,
                    isHost: false,
                });
            }
        });
    }
    clearUI() {
        if (this.inputContainer) {
            this.inputContainer.destroy();
        }
        this.createAiButton.destroy();
        this.createButton.destroy();
        this.joinButton.destroy();
        this.creditsButton.destroy();
    }
}
