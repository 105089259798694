import * as Phaser from "phaser";
import Platform from "../components/platform";
import Pirate, { LEFT_PIRATE_POS, RIGHT_PIRATE_POS } from "../components/pirate";
import RoomService from "../network/roomService";
import GamePlayUI from "../components/gamePlayUI";
export default class GamePlayScene extends Phaser.Scene {
    constructor() {
        super({ key: "GamePlayScene" });
        this.gameOver = false;
    }
    init(args) {
        this.roomService = new RoomService(this, args.connection, args.roomId, args.userId, args.isHost);
    }
    preload() {
        this.load.image("water", "../../assets/water.png");
        this.load.image("wood", "../../assets/wood.png");
        this.load.image("pirate", "../../assets/pirate.png");
        this.load.image("cannonball", "../../assets/cannonball.png");
    }
    create() {
        var _a;
        const water = this.add.image(0, 0, "water").setOrigin(0);
        const scaleX = this.game.config.width / water.width;
        const scaleY = this.game.config.height / water.height;
        const scale = Math.max(scaleX, scaleY);
        water.setScale(scale).setScrollFactor(0);
        this.spawnPlayer();
        this.input.setDefaultCursor("url(../../assets/cursor.png), pointer");
        (_a = this.input.mouse) === null || _a === void 0 ? void 0 : _a.disableContextMenu();
        this.ui = new GamePlayUI(this, this.roomService.IsHost(), this.roomService.getRoomId());
    }
    spawnEnemyPirate() {
        this.enemy = new Pirate(this, RIGHT_PIRATE_POS.x, RIGHT_PIRATE_POS.y, "pirate", true);
    }
    spawnPlayer() {
        if (this.roomService.IsHost()) {
            // TODO platformA should always be left, and belong to different user
            this.platformA = new Platform(this, true);
            this.platformB = new Platform(this, false, true);
            this.pirate = new Pirate(this, LEFT_PIRATE_POS.x, LEFT_PIRATE_POS.y, "pirate");
        }
        else {
            this.platformA = new Platform(this, false);
            this.platformB = new Platform(this, true, true);
            this.enemy = new Pirate(this, LEFT_PIRATE_POS.x, LEFT_PIRATE_POS.y, "pirate", true);
            this.pirate = new Pirate(this, RIGHT_PIRATE_POS.x, RIGHT_PIRATE_POS.y, "pirate");
        }
    }
    update() {
        var _a, _b;
        (_a = this.pirate) === null || _a === void 0 ? void 0 : _a.update();
        (_b = this.enemy) === null || _b === void 0 ? void 0 : _b.update();
    }
    scenePause() {
        this.scene.remove();
    }
}
