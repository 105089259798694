import * as Phaser from "phaser";
export default class Cannonball {
    constructor(scene) {
        this.sceneRef = scene;
        this.targetPos = new Phaser.Math.Vector2(0, 0);
    }
    shootTo(target, origin) {
        const cannonball = this.sceneRef.physics.add.sprite(origin.x, origin.y, "cannonball");
        this.sceneRef.tweens.add({
            targets: cannonball,
            x: target.x,
            y: target.y,
            duration: 1000,
            onComplete: () => {
                cannonball.destroy();
            },
            onUpdate: () => { },
        });
    }
}
